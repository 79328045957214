import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";

import banner from '../assets/images/LP-vertere/banner-quero-apoiar.png'
import bannerMobile from '../assets/images/LP-vertere/banner-quero-apoiar-mobile.png'
import bannerTablet from '../assets/images/LP-vertere/banner-quero-apoiar-tablet.png'

const ServiceDetailsPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Quero Apoiar">
          <HeaderOne />
          <PageBanner backgroundImage={banner} backgroundImageMobile={bannerMobile} backgroundImageTablet={bannerTablet} pageName='QUERO APOIAR' />
          <ServiceDetails />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPage;
