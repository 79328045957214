/** @jsx jsx */
import { css } from '@emotion/react'

export const serviceDetail = css`
  padding-bottom: 82px;
  h2 {
    font-size: 28px;
    font-weight: 700;
    color: #4766a8;
    line-height: 33.6px;
  }
  .book {
    display: flex;
    margin-top: 119px;
    align-items: center;
    @media (max-width: 500px) {
      flex-direction: column;
      margin-top: 64px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      margin-top: 61px;
    }
  }
  .bookTablet {
    display: none;
    @media (min-width: 500px) and (max-width: 850px) {
      display: inline;
    }
  }
  p {
    font-size: 16px;
    line-height: 150%;
    margin: 0;
  }
  .text {
    margin-right: 163px;
    @media (max-width: 500px) {
      margin-right: 64px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      strong {
        font-weight: 400;
      }
    }
  }
  .bookDesktop {
    width: 503px;
    @media (max-width: 850px) {
      display: none;
    }
  }
  .bookMobile {
    display: none;
    @media (max-width: 500px) {
      display: inline;
      width: 100%;
      margin-top: 50px;
    }
  }
  .textMobile {
    display: none;
    @media (max-width: 500px) {
      display: block;
      width: 270px !important;
      font-size: 16px;
      line-height: 150%;
    }
  }
  .textDesktop {
    @media (max-width: 500px) {
      display: none;
    }
  }
  .borderBottom {
    border-bottom: 1px solid rgba(14, 20, 34, 0.3);
    padding-bottom: 26px;
    width: 612px;
    @media (max-width: 500px) {
      font-size: 24px;
      padding-bottom: 20px;
      width: 270px !important;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      border-bottom: none;
      padding: 0;
    }
  }
  .section {
    margin-top: 112px;
    @media (max-width: 500px) {
      margin-top: 63px;
    }
    p {
      margin-top: 29px;
      width: 601px;
      color: #212121;
      font-size: 18px;
      line-height: 150%;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      margin-top: 98px;
    }
  }
  .buttonSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 247px;
    margin-bottom: 263px;
    @media (max-width: 500px) {
      margin: 100px 0 100px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      margin-top: 156px;
      margin-bottom: 156px;
    }
    br {
      display: none;
      @media (max-width: 500px) {
        display: inline;
      }
    }
    p {
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #212121;
      margin-bottom: 33px;
      @media (max-width: 500px) {
        font-size: 28px;
        line-height: 120%;
        margin-bottom: 25px;
      }
    }
    a {
      color: #f7f4fd;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      line-height: 21.6px;
      padding: 8px 16px 8px 16px;
      background-color: #0c79fe;
      border-radius: 8px;
      @media (max-width: 500px) {
        font-size: 16px;
        line-height: 120%;
      }
    }
    a:hover {
      background-color: #4766a8;
    }
  }
`

export const styles = css`
  margin-top: 60px;
  @media (max-width: 850px) {
    margin-top: 0px;
  }
  br:first-child {
    display: none;
    @media (max-width: 500px) {
      display: inline;
    }
  }
  .title {
    color: #4766a8;
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 48px;
    width: 810px;
    line-height: 120%;
    @media (min-width: 500px) and (max-width: 850px) {
      font-size: 24px;
    }
    span {
      color: #212121;
    }
    @media (max-width: 500px) {
      width: 100%;
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
  .textOne {
    color: #212121;
    line-height: 150%;
    font-size: 18px;
    width: 670px;
    font-weight: 400;
    margin-bottom: 0;
    @media (min-width: 500px) and (max-width: 850px) {
      display: none;
    }
    @media (max-width: 500px) {
      display: inline;
      width: 100%;
      font-size: 16px;
      span {
        font-weight: 700;
      }
    }
  }
  .textTwo {
    color: #212121;
    line-height: 150%;
    font-size: 18px;
    width: 670px;
    font-weight: bold;
    @media (min-width: 500px) and (max-width: 850px) {
      display: none;
    }
    @media (max-width: 500px) {
      display: inline;
      font-weight: 400;
      width: 100%;
      font-size: 16px;
    }
  }
  .textTablet {
    display: none;
    @media (min-width: 500px) and (max-width: 850px) {
      display: flex;
      width: 500px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }
`
